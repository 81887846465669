import React, { FC } from "react";

// Props type
interface Props {
    containerClass?: string;
}

// 404 Component
/**
 * This component is rendered when the searched resource is not found
 */

const Error404: FC<Props> = (props) => (
    <div className="container">
        <div className="error-msg full-width">
            <div
                className={
                    props.containerClass ||
                    "flex flex-justify-center flex-align-center flex-column p-y-spacer-xl m-b-spacer-xl"
                }
            >
                <h1>404</h1>
                <h3 className="text-center">We couldn&#39;t find that page.</h3>
                <small>Try these helpful links instead:</small>
            </div>
        </div>

        <div className="flex flex-justify-center full-width m-b-spacer-xl wrap-mobile">
            <a
                href="https://elevationchurch.org/sermons"
                className="m-x-spacer-sm rel-pos m-t-spacer-md"
            >
                <div className="flex flex-justify-center">
                    <div
                        className="flex flex-justify-center flex-align-center flex-align-end ec-text-white ec-border-radius"
                        style={{
                            backgroundImage:
                                "url('//dv9pmwasxvawj.cloudfront.net/images/sermons404Page.jpg')",
                            width: "300px",
                            height: "200px",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    >
                        <h3 style={{ zIndex: 11 }}>Watch A Sermon</h3>
                    </div>
                    <div className="abs-pos half-height full-width medium-gradient-background more-forward downlow stayleft ec-border-radius"></div>
                </div>
                <div className="abs-pos half-height full-width medium-gradient-background more-forward downlow stayleft ec-border-radius" />
            </a>

            <a
                href="https://elevationchurch.org/locations"
                className="m-x-spacer-sm rel-pos ec-border-radius m-t-spacer-md"
            >
                <div className="flex flex-justify-center">
                    <div
                        className="flex flex-justify-center flex-align-center flex-align-end ec-text-white ec-border-radius"
                        style={{
                            backgroundImage:
                                "url('//dv9pmwasxvawj.cloudfront.net/images/FindALocation404Page.jpg')",
                            width: "300px",
                            height: "200px",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    >
                        <h3 style={{ zIndex: 11 }}>Find A Location</h3>
                    </div>
                    <div className="abs-pos half-height full-width medium-gradient-background more-forward downlow stayleft ec-border-radius"></div>
                </div>
                <div className="abs-pos half-height full-width medium-gradient-background more-forward downlow stayleft ec-border-radius" />
            </a>

            <a
                href="https://elevationchurch.org/online"
                className="m-x-spacer-sm rel-pos m-t-spacer-md"
            >
                <div className="flex flex-justify-center">
                    <div
                        className="flex flex-justify-center flex-align-center flex-align-end ec-text-white ec-border-radius"
                        style={{
                            backgroundImage:
                                "url('//dv9pmwasxvawj.cloudfront.net/images/online404Page.jpg')",
                            width: "300px",
                            height: "200px",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    >
                        <h3 style={{ zIndex: 11 }}>Join Us Live</h3>
                    </div>
                    <div className="abs-pos half-height full-width medium-gradient-background more-forward downlow stayleft ec-border-radius"></div>
                </div>
                <div className="abs-pos half-height full-width medium-gradient-background more-forward downlow stayleft ec-border-radius" />
            </a>
        </div>
    </div>
);

export default Error404;
