import { FC } from "react";
import Error404 from "../src/js/components/404";

// 404 Component
/**
 * This component is rendered when the searched resource is not found
 */

const ErrorPage: FC = () => <Error404 />;

export default ErrorPage;
